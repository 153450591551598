import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import Button from 'o-ui/Button';
import Checkbox from 'o-ui/Checkbox';
import DialogActions from 'o-ui/Dialog/DialogActions';
import DialogContent from 'o-ui/Dialog/DialogContent';
import {Emoji} from 'o-ui/EmojiPicker/EmojiPicker';
import FormGroup from 'o-ui/FormGroup';
import FormLabel from 'o-ui/FormLabel';

import {useStore} from '../stores/AppStore';
import {ModalItem} from '../stores/ModalsStore';

import FileInput from '../components/FileUploader/FileInput';
import {QuillTextInput} from '../components/QuillTextInput';
import QuillEditor from '../components/QuillTextInput/QuillEditor';
import ModalDialog, {ModalDialogRef} from './components/ModalDialog';
import QuillTextCounter from './components/QuillTextCounter';
import UploadAudioInfo from './components/UploadAudioInfo';
import UploadFileInfo from './components/UploadFileInfo';

import {MAX_TELEGRAM_MESSAGE_WITH_MEDIA_TEXT_LENGTH} from '../constants';
import {entities} from '../api/proto';
import useFileChangeHendler from '../components/FileUploader/useFileChangeHendler';
import {MAX_FILE_UPLOAD_MB} from '../config';
import ChatEmojiButton from '../pages/Chat/UserChat/ChatInput/ChatEmojiButton';
import {FileData} from '../utils/file/fileReaders';
import {validateFileSize} from '../utils/file/fileSize';

interface IProps {
  modal: ModalItem;
}

export const ModalUploadFileAsync: React.FC<IProps> = observer(({modal}) => {
  const {t} = useTranslation();
  const modalRef = React.useRef<ModalDialogRef>(null);

  const {notifications} = useStore();
  const {dataFiles, chat} = modal.data;

  const [title, setTitle] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [countFiles, setCountFiles] = useState<number>(0);
  const [countPhotoFiles, setCountPhotoFiles] = useState<number>(0);
  const [countVideoFiles, setCountVideoFiles] = useState<number>(0);
  const [countAudioFiles, setCountAudioFiles] = useState<number>(0);

  const [groupInAlbum, setGroupInAlbum] = useState<boolean>(!chat?.channel.isWebWidget);

  const refFileInput = useRef<HTMLInputElement>(null);

  const measuredRef = React.useCallback(
    (node: QuillEditor) => {
      chat?.store?.fileCaptionEditor?.setEditorRef(node);
      chat?.store?.fileCaptionEditor?.focus();
    },
    [chat?.store?.fileCaptionEditor],
  );

  useEffect(() => {
    chat?.store?.fileCaptionEditor?.focus();

    return () => {
      chat?.store?.fileCaptionEditor?.setText(null);
    };
  }, [chat]);

  useEffect(() => {
    setCountFiles(dataFiles?.length || 0);
    setCountPhotoFiles(dataFiles?.filter((f) => f.image).length || 0);
    setCountVideoFiles(dataFiles?.filter((f) => f.video).length || 0);
    setCountAudioFiles(dataFiles?.filter((f) => f.audio).length || 0);
  }, [dataFiles]);

  useEffect(() => {
    if (countFiles === countPhotoFiles) {
      setTitle(t('modal_upload_send_photo'));
    } else if (countFiles === countVideoFiles) {
      setTitle(t('modal_upload_send_video'));
    } else if (countFiles === countAudioFiles) {
      setTitle(t('modal_upload_send_audio'));
    } else {
      setTitle(countFiles > 1 ? t('modal_upload_send_file') : t('modal_upload_send_files'));
    }
  }, [countFiles, countVideoFiles, countPhotoFiles, countAudioFiles, t]);

  const handleChangeGroupInAlbum = () => {
    setGroupInAlbum(!groupInAlbum);
  };

  const handleSubmit = () => {
    if (chat && modal.data.dataFiles && !uploading) {
      try {
        if (modal.data.dataFiles.some((fileData) => !validateFileSize(fileData.file))) {
          throw new Error(t(`modal_upload_max_size_to_upload_notification`, {maxSize: MAX_FILE_UPLOAD_MB}));
        }

        setUploading(true);

        const text = chat?.store?.fileCaptionEditor?.text || '';

        chat?.store?.sendMessage({
          text,
          dataFiles: modal.data.dataFiles,
          groupInAlbum: groupInAlbum,
          compressImages: false,
          asDocumentType: true,
          textEntities: chat?.store?.fileCaptionEditor?.textEntities,
          replyMessage: chat.store?.replyMessage,
        });

        handleCloseClick();
      } catch (e: any) {
        setUploading(false);
        notifications.error(e.message);
      }
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if ((e.keyCode === 13 || e.code === 'Enter' || e.code === 'NumpadEnter') && !e.shiftKey && !e.ctrlKey) {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit();
      }
    };
    window.document.addEventListener('keydown', listener);

    return () => {
      window.document.removeEventListener('keydown', listener);
    };
  });

  const handleDataFiles = action((newDataFiles: FileData[]) => {
    if (newDataFiles) {
      newDataFiles.forEach((dataFile) => {
        modal.data.dataFiles?.push(dataFile);
      });

      if (modal.data.dataFiles) {
        modal.data.dataFiles = modal.data.dataFiles.slice();
      }
    }
  });

  const handleDeleteFile = action((objectUrl?: string) => {
    if (!objectUrl) return;

    modal.data.dataFiles = modal.data.dataFiles?.filter((file) => file.objectUrl !== objectUrl);

    if (modal.data.dataFiles) {
      modal.data.dataFiles = modal.data.dataFiles.slice();
    }

    if (!modal.data.dataFiles?.length) {
      handleCloseClick();
    }
  });

  const handleAddFiles = useFileChangeHendler(handleDataFiles);

  const handleClickAddBtn = () => {
    refFileInput.current?.click();
  };

  const addEmoji = (e: Emoji) => {
    const emoji = e.getNative();
    chat?.store?.fileCaptionEditor?.insertText(emoji);
  };

  const handleCloseClick = () => {
    // chat?.store?.editor?.setContents(chat?.store?.fileCaptionEditor?.getContents());

    modalRef.current?.close();
  };

  const getCaptionTextLimit = (type?: entities.OzekonChannelType | null): null | number => {
    switch (type) {
      case entities.OzekonChannelType.OIT_TELEGRAM:
        return MAX_TELEGRAM_MESSAGE_WITH_MEDIA_TEXT_LENGTH;

      default:
        return null;
    }
  };

  return (
    <ModalDialog
      ref={modalRef}
      modal={modal}
      title={title}
      maxWidth="sm"
      fullWidth={true}
      scroll="body"
      PaperProps={{
        className: 'modal--upload overflow-initial',
        id: 'uploadFileModal',
      }}
      onClose={handleCloseClick}
    >
      <DialogContent className="overflow-initial">
        <div className="mt-6 media-wrapper custom-scroll flex-row">
          {modal.data.dataFiles?.map((dataFile) => (
            <div key={dataFile.objectUrl} className="card-body d-flex p-0 w-100 mb-5">
              {dataFile.audio ? (
                <UploadAudioInfo
                  name={dataFile.file.name}
                  type={dataFile.file.type}
                  size={dataFile.file.size}
                  objectUrl={dataFile.objectUrl}
                  onDelete={handleDeleteFile}
                />
              ) : (
                <UploadFileInfo
                  name={dataFile.file.name}
                  type={dataFile.file.type}
                  size={dataFile.file.size}
                  objectUrl={dataFile.objectUrl}
                  onDelete={handleDeleteFile}
                />
              )}
            </div>
          ))}
        </div>

        {!chat?.store?.isWebWidget && modal.data.dataFiles && modal.data.dataFiles.length > 1 ? (
          <div
            className={cn('mb-5', {
              'pt-5': modal.data.dataFiles.length > 5,
            })}
          >
            <Checkbox
              checked={groupInAlbum}
              onChange={handleChangeGroupInAlbum}
              label={t('modal_upload_group_items_label')}
            />
          </div>
        ) : null}

        <FormGroup className="mb-6">
          <FormLabel>{t('modal_upload_caption_label')}</FormLabel>
          <QuillTextInput
            ref={measuredRef}
            className="w-100 form-field withEmojiBtn position-relative"
            editorClassName="caption-field"
            defaultValue={chat?.store?.editor?.getContents()}
            placeholder={t('modal_upload_caption_placeholder')}
            onEnter={handleSubmit}
            bounds="#uploadFileModal"
          >
            <div className="emoji-container">
              {chat ? (
                <ChatEmojiButton
                  popperOffset={[6, 5]}
                  chat={chat}
                  placement="top-end"
                  transformOrigin="bottom right"
                  onAddEmoji={addEmoji}
                  hideStickers={true}
                />
              ) : null}
            </div>
          </QuillTextInput>

          <QuillTextCounter
            quill={chat?.store?.fileCaptionEditor?.quill}
            maxTextLength={getCaptionTextLimit(chat?.channel.type)}
          />
        </FormGroup>

        <FileInput className="d-none" ref={refFileInput} onChange={handleAddFiles} multiple={true} />
      </DialogContent>

      <DialogActions className="justify-content-end">
        <Button className="mr-auto" variant="contained" color="secondary" size="large" onClick={handleClickAddBtn}>
          {t('modal_upload_add_button')}
        </Button>
        <Button variant="contained" color="tertiary" size="large" onClick={handleCloseClick}>
          {t('modal_cancel_btn')}
        </Button>
        <Button
          loading={uploading}
          variant="contained"
          color="primary"
          size="large"
          disabled={uploading}
          onClick={handleSubmit}
        >
          {t('modal_upload_send_button')}
        </Button>
      </DialogActions>
    </ModalDialog>
  );
});

export default ModalUploadFileAsync;
