import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import Checkbox from 'o-ui/Checkbox';
import FormHelperText from 'o-ui/FormHelperText';
import InputAdornment from 'o-ui/Input/InputAdornment';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';
import Link from 'o-ui/Link';

import {getUserResponseStatusError} from '../../../api/getErrorByType';
import {entities} from '../../../api/proto';
import ButtonWithTimer from '../../../components/ButtonWithTimer';
import SnackMessage from '../../../components/SnackMessage';
import {PASSWORD_MIN_LENGTH, PREFERABLE_TARIFF_ID} from '../../../constants';
import {Paths} from '../../../routes/Paths';
import useStore from '../../../stores/useStore';
import browserStorage from '../../../utils/browserStorage';
import {formatDuration} from '../../../utils/format';
import TariffPlanInfo from './TariffPlanInfo';
import useTariffId from './useTariffId';

const {UserRegisterResult} = entities;

interface IProps {
  className?: string;
}

export const RegistrationForm = observer((props: IProps) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {activeWorkspace, notifications, userStore} = useStore();
  const location = useLocation();
  const tariffId = useTariffId();

  React.useEffect(() => {
    activeWorkspace.billing.initTariffs();
  }, [activeWorkspace]);

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const handleToggleTermsAccepted = () => {
    setTermsAccepted(!termsAccepted);
  };

  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().email(t('registration_email_validation')).required(t('registration_email_required')),
      password: Yup.string()
        .required(t('registration_password_required'))
        .min(PASSWORD_MIN_LENGTH, t('registration_password_validation_size', {minSize: PASSWORD_MIN_LENGTH})),
    }),
    onSubmit: async (values) => {
      setApiError('');
      const {error, res} = await userStore.register(values);

      if (error) {
        notifications.error(error.message);
        setApiError(error.message);
      }

      if (res) {
        const statusErrorMessage = getUserResponseStatusError('register', res?.status);

        if (
          res?.status &&
          [
            UserRegisterResult.RS_ALREADY_REGISTERED,
            UserRegisterResult.RS_EMAIL_USED,
            UserRegisterResult.RS_INVALID_EMAIL,
          ].includes(res.status) &&
          statusErrorMessage
        ) {
          formik.setErrors({email: statusErrorMessage});
          return;
        }

        if (
          res?.status &&
          [UserRegisterResult.RS_PASSWORDS_MISMATCH, UserRegisterResult.RS_PASSWORD_TOO_SHORT].includes(res.status) &&
          statusErrorMessage
        ) {
          formik.setErrors({password: statusErrorMessage});
          return;
        }

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        if (location.state?.from && !tariffId) {
          navigate(location.state?.from?.pathname, {replace: true});
        } else {
          navigate(Paths.RegistrationComplete, {
            replace: true,
            state: {
              tariffId,
            },
          });
        }

        if (tariffId) {
          browserStorage.local(PREFERABLE_TARIFF_ID, tariffId);
        }
      }
    },
  });

  const handleChange = (e: React.ChangeEvent) => {
    formik.handleChange(e);
    setApiError('');
  };

  return (
    <form
      className={cn('auth-page__form', props.className)}
      onSubmit={formik.handleSubmit}
      noValidate={true}
    >
      {tariffId ? (
        <TariffPlanInfo
          className="mb-6"
          tariffId={tariffId}
        />
      ) : null}

      {userStore.registerUserTimer.inProgress ? (
        <SnackMessage
          className="mt-5 mb-6"
          message={t('notification_too_many_request_message')}
          subMessage={t('notification_too_many_request_sub_message', {
            time: formatDuration(userStore.registerUserTimer.currentTime * 1000),
          })}
          variant="error"
          hideCloseIcon
        />
      ) : null}

      <OutlinedTextInput
        className="w-100"
        name="email"
        placeholder={t('registration_email_placeholder')}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={!!(formik.touched.email && formik.errors.email)}
        errorHelper={formik.errors.email || ' '}
        required
      />

      <OutlinedTextInput
        className="w-100 mb-5"
        name="password"
        placeholder={t('registration_password_placeholder')}
        textHelper={t('registration_password_helper', {minSize: PASSWORD_MIN_LENGTH})}
        type={showPassword ? 'text' : 'password'}
        error={!!(formik.touched.password && formik.errors.password)}
        errorHelper={formik.errors.password || ' '}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        required
        endAdornment={
          <InputAdornment position="end">
            <VisibilitySwitcher onClick={handleClickShowPassword} visible={showPassword} />
          </InputAdornment>
        }
      />

      <div className="auth-page__accept-terms" onClick={handleToggleTermsAccepted}>
        <Checkbox className="mr-3" checked={termsAccepted} />
        <span>
          {t('registration_processing_personal_data')}
          <Link
            href="/tos.pdf"
            target="_blank"
            className="ml-3"
          >
            {t('registration_processing_personal_data_agreements_offers')}
          </Link>
        </span>
      </div>

      <ButtonWithTimer
        className="w-100"
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={!termsAccepted}
        loading={userStore.loading}
        timer={userStore.registerUserTimer}
      >
        {t('registration_sign_up')}
      </ButtonWithTimer>

      <div className="auth-page__join-link">
        {t('registration_have_account')}
        <RouterLink to={Paths.Login} className="auth-page__link">
          {t('registration_back_to_login')}
        </RouterLink>
      </div>

      <FormHelperText className="mt-3" error>
        {apiError || ' '}
      </FormHelperText>
    </form>
  );
});

export default RegistrationForm;
