import cn from 'classnames';
import {useFormik} from 'formik';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'o-ui/Button';
import Checkbox from 'o-ui/Checkbox';
import FormHelperText from 'o-ui/FormHelperText';
import InputAdornment from 'o-ui/Input/InputAdornment';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import VisibilitySwitcher from 'o-ui/Input/VisibilitySwitcher';
import Link from 'o-ui/Link';

import {PASSWORD_MIN_LENGTH} from '../../constants';
import {getUserResponseStatusError} from '../../api/getErrorByType';
import {entities} from '../../api/proto';
import {BRAND_NAME} from '../../config';
import {Paths} from '../../routes/Paths';
import {ModalType} from '../../stores/ModalType';
import useStore from '../../stores/useStore';
import {decodeInviteCode} from '../../utils/formatUrl';

const {UserRegisterResult} = entities;

interface PrivateRegistrationFormProps {
  className?: string;
  code: string;
  email?: string | null;
}

export const PrivateRegistrationForm: React.FC<PrivateRegistrationFormProps> = observer((props) => {
  const navigate = useNavigate();
  const {activeWorkspace, modals, notifications, userStore} = useStore();
  const {t} = useTranslation();

  const [termsAccepted, setTermsAccepted] = React.useState<boolean>(false);
  const handleToggleTermsAccepted = () => {
    setTermsAccepted(!termsAccepted);
  };

  const [apiError, setApiError] = React.useState<string | null | undefined>('');
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: props.email || '',
      password: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().email(t('registration_email_validation')).required(t('registration_email_required')),
      password: Yup.string()
        .required(t('registration_password_required'))
        .min(6, t('registration_password_validation_size', {minSize: PASSWORD_MIN_LENGTH})),
    }),
    onSubmit: async (values) => {
      const inviteID = decodeInviteCode(props.code);
      const {error, res} = await userStore.register(values, inviteID);

      if (error) {
        notifications.error(error.message);
        setApiError(error.message);
      }

      if (res) {
        const statusErrorMessage = getUserResponseStatusError('register', res?.status);

        if (UserRegisterResult.RS_EMAIL_USED === res?.status && activeWorkspace.invites.registrationInvite) {
          notifications.warning(
            t('registration_member_already_exist_in_system', {
              email: values.email,
              brandName: BRAND_NAME,
            }),
          );
          navigate(Paths.Login, {state: {email: values.email}});
          return;
        }

        if (
          res?.status &&
          [
            UserRegisterResult.RS_ALREADY_REGISTERED,
            UserRegisterResult.RS_EMAIL_USED,
            UserRegisterResult.RS_INVALID_EMAIL,
          ].includes(res.status) &&
          statusErrorMessage
        ) {
          formik.setErrors({email: statusErrorMessage});
          return;
        }

        if (
          res?.status &&
          [UserRegisterResult.RS_PASSWORDS_MISMATCH, UserRegisterResult.RS_PASSWORD_TOO_SHORT].includes(res.status) &&
          statusErrorMessage
        ) {
          formik.setErrors({password: statusErrorMessage});
          return;
        }

        if (statusErrorMessage) {
          notifications.error(statusErrorMessage);
          setApiError(statusErrorMessage);
          return;
        }

        activeWorkspace.invites.clearRegistrationInvite();
        navigate(Paths.RegistrationComplete, {replace: true});
      }
    },
    onReset: () => {
      setApiError('');
    },
  });

  const {setFieldValue} = formik;

  React.useEffect(() => {
    if (props.email) {
      setFieldValue('email', props.email);
    }
  }, [props.email, setFieldValue]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userStore.isLoggedIn) {
      modals.open(ModalType.CONFIRM, {
        headerText: t('registration_modal_logout_title'),
        content: <div className="body2-regular">{t('registration_modal_logout_description')}</div>,
        submitButtonText: t('registration_modal_logout_title'),
        callbacks: {
          submit: async () => {
            const {error} = await userStore.logout(true);

            if (!error) {
              notifications.success(t('registration_modal_success_logout_notification'));
            }
          },
        },
      });
    } else {
      formik.submitForm();
    }
  };

  const handleChange = (e: React.ChangeEvent) => {
    formik.handleChange(e);
    setApiError('');
  };

  return (
    <form className={cn('auth-page__form', props.className)} onSubmit={handleSubmit}>
      <OutlinedTextInput
        className="w-100"
        name="email"
        placeholder={t('registration_email_placeholder')}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        errorHelper={formik.errors.email || ' '}
        required
        disabled={!!props.email}
      />

      <OutlinedTextInput
        className="w-100 mb-5"
        name="password"
        placeholder={t('registration_password_placeholder')}
        textHelper={t('registration_password_helper', {minSize: PASSWORD_MIN_LENGTH})}
        type={showPassword ? 'text' : 'password'}
        error={!!(formik.touched.password && formik.errors.password)}
        errorHelper={formik.errors.password || ' '}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        required
        endAdornment={
          <InputAdornment position="end">
            <VisibilitySwitcher onClick={handleClickShowPassword} visible={showPassword} />
          </InputAdornment>
        }
      />

      <div className="auth-page__accept-terms" onClick={handleToggleTermsAccepted}>
        <Checkbox className="mr-3" checked={termsAccepted} />
        <span>
          {t('registration_processing_personal_data')}
          <Link
            href="/tos.pdf"
            target="_blank"
            className="pl-3"
          >
            {t('registration_processing_personal_data_agreements_offers')}
          </Link>
        </span>
      </div>

      <Button
        className="w-100"
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={!termsAccepted}
        loading={userStore.loading}
      >
        {t('registration_sign_up')}
      </Button>

      <div className="auth-page__join-link">
        {t('registration_have_account')}
        <RouterLink to={Paths.Login} className="auth-page__link">
          {t('registration_back_to_login')}
        </RouterLink>
      </div>

      <FormHelperText className="mt-3" error>
        {apiError || ' '}
      </FormHelperText>
    </form>
  );
});

export default PrivateRegistrationForm;