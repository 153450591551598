import cn from 'classnames';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated} from 'react-spring';

import Button from 'o-ui/Button';
import LinearProgress from 'o-ui/LinearProgress';

import Div100vh, {use100vh} from '../../components/Div100vh';
import {BRAND_NAME} from '../../config';
import useSlideTransition from '../../hooks/useSlideTransition';
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate';
import {Paths} from '../../routes/Paths';
import OnboardingStepType from '../../stores/LayOutStore/OnboardingStepType';
import useStore from '../../stores/useStore';
import getQueryStringParam from '../../utils/getQueryStringParam';
import {setStyleProperty} from '../../utils/styleProperty';
import ChannelCreationStepType from '../Settings/Channels/ChannelCreationStepType';
import WorkspaceSettingsForm, {IWorkspaceSettingsFormRef} from '../Settings/WorkspaceSettings/WorkspaceSettingsForm';
import BillingStep from './BillingStep';
import useHighlightedTariff from './BillingStep/useHighlightedTariff';
import ChannelCreationStep from './ChannelCreationStep';
import OperatorsInvitationStep from './OperatorsInvitationStep';
import PersonalInfoForm, {IPersonalInfoFormRef} from './PersonalInfoForm';

import logoBlueImg from '../../assets/images/logo-blue.svg';
import personalInfoIllustration from '../../assets/images/personal-info-illustration.png';
import ContextMenu from '../../contextMenu/ContextMenu';


const debug: string = getQueryStringParam('debug');
const qsStep: string = getQueryStringParam('step');

const INITIAL_STEP = debug && qsStep ? parseFloat(qsStep) : 0;

const OnboardingPage = observer(() => {
  const height = use100vh();
  const {activeWorkspace, anal, layOutStore, userStore} = useStore();

  const workspaceNavigate = useWorkspaceNavigate();

  const {t} = useTranslation();
  const [prevStep, setPrevStep] = React.useState<number>(INITIAL_STEP);
  const [currentStep, setCurrentStep] = React.useState<number>(INITIAL_STEP);

  const getStepType = React.useCallback(
    (step: number) => layOutStore.onboardingSteps[step],
    [layOutStore.onboardingSteps],
  );

  const personalInfoFormRef = React.useRef<IPersonalInfoFormRef>(null);
  const workspaceSettingsFormRef = React.useRef<IWorkspaceSettingsFormRef>(null);

  const loading = React.useMemo(() => {
    if (getStepType(currentStep) === OnboardingStepType.WORKSPACE_SETTINGS) {
      return activeWorkspace.updateInfoInProcess;
    }
    return userStore.loading;
  }, [
    getStepType,
    currentStep,
    activeWorkspace.updateInfoInProcess,
    userStore.loading,
  ]);

  const highlightTariff = useHighlightedTariff();

  React.useEffect(() => {
    if (getStepType(currentStep) === OnboardingStepType.BILLING) {
      anal.onboardingCheckout(highlightTariff);
    }
  }, [
    anal,
    highlightTariff,
    currentStep,
    getStepType,
  ]);

  React.useEffect(() => {
    layOutStore.theme.switchOnLightTheme();
  }, [layOutStore]);

  React.useEffect(() => {
    setStyleProperty('--onboarding-height', height ? `${height}px` : '100vh');
  }, [height]);

  const switchToStep = (step: number) => {
    setPrevStep(currentStep);
    setCurrentStep(step);
  };

  const switchToNextStep = () => {
    if (currentStep < layOutStore.onboardingSteps.length - 1) {
      switchToStep(currentStep + 1);
    }
  };

  const switchToPrevStep = () => {
    if (currentStep > 0) {
      switchToStep(currentStep - 1);
    }
  };

  const handleNextClick = () => {
    if (currentStep === OnboardingStepType.PERSONAL_INFO) {
      if (personalInfoFormRef.current?.submitForm) {
        personalInfoFormRef.current.submitForm();
      }
    } else if (currentStep === OnboardingStepType.WORKSPACE_SETTINGS) {
      if (workspaceSettingsFormRef.current?.submitForm) {
        workspaceSettingsFormRef.current.submitForm();
      }
    } else {
      switchToNextStep();
    }
  };

  const handleCloseClick = () => {
    workspaceNavigate(Paths.Chat);
  };

  const handleSubmitPersonalInfo = () => {
    switchToNextStep();
  };

  const handleSubmitWorkspaceInfo = () => {
    switchToNextStep();
  };

  const handleSkipOnboarding = () => {
    switchToNextStep();
  };

  const transitions = useSlideTransition(currentStep, prevStep);

  const backButtonVisible: boolean = currentStep !== OnboardingStepType.PERSONAL_INFO;
  const lastStep: boolean = currentStep === layOutStore.onboardingSteps.length - 1;

  const [currentAccountCreationStep, setCurrentAccountCreationStep] = React.useState<
    ChannelCreationStepType
  >(ChannelCreationStepType.CHANNEL_INFO);

  const handleChangeChannelCreationStep = (newChannelStep: ChannelCreationStepType) => {
    setCurrentAccountCreationStep(newChannelStep);
  };

  const renderNextButton = () => {
    if (lastStep) {
      return activeWorkspace.billing.hasAccess ? null : (
        <Button
          className={cn('onboarding__next-btn', {'ml-3': backButtonVisible})}
          variant="contained"
          size="large"
          onClick={handleCloseClick}
          loading={loading}
        >
          {t('Get started')}
        </Button>
      );
    }

    return (
      <Button
        className={cn('onboarding__next-btn', {'ml-3': backButtonVisible})}
        variant="contained"
        size="large"
        onClick={handleNextClick}
        loading={loading}
        disabled={
          currentStep === OnboardingStepType.CHANNEL_CREATION &&
          currentAccountCreationStep !== ChannelCreationStepType.SUCCESS
        }
      >
        {t('Next')}
      </Button>
    );
  };

  const renderStep = (step: number) => {
    const stepType_ = getStepType(step);

    switch (stepType_) {
      case OnboardingStepType.PERSONAL_INFO:
        return (
          <>
            <PersonalInfoForm
              ref={personalInfoFormRef}
              title={t('onboarding_personal_info_title', {brandName: BRAND_NAME})}
              subTitle={t('onboarding_personal_info_subtitle')}
              onSubmit={handleSubmitPersonalInfo}
            />
            <img className="onboarding__illustration-img" src={personalInfoIllustration} alt="" />
          </>
        );
      case OnboardingStepType.WORKSPACE_SETTINGS:
        return (
          <WorkspaceSettingsForm
            ref={workspaceSettingsFormRef}
            title={t('onboarding_workspace_info_title')}
            subTitle={t('onboarding_workspace_info_subtitle')}
            onSubmit={handleSubmitWorkspaceInfo}
          />
        );
      case OnboardingStepType.CHANNEL_CREATION:
        return (
          <ChannelCreationStep onChangeStep={handleChangeChannelCreationStep} />
        );
      case OnboardingStepType.BILLING:
        return (
          <BillingStep />
        );
      case OnboardingStepType.OPERATORS_INVITATION:
        return (
          <OperatorsInvitationStep />
        );
      default:
        return null;
    }
  };

  return (
    <Div100vh className="onboarding">
      <div className="onboarding__header">
        <img className="onboarding__header-logo" src={logoBlueImg} alt="" />
        <span className="onboarding__header-step body1-regular color-body-primary">
          {t('Step {{number}} of {{total}}', {number: currentStep + 1, total: layOutStore.onboardingSteps.length})}
        </span>
        {lastStep ? null : (
          <Button
            className="onboarding__header-skip"
            variant="contained"
            color="secondary"
            onClick={handleSkipOnboarding}
          >
            {t('Skip')}
          </Button>
        )}

        <LinearProgress
          className="onboarding__header-progress mt-auto"
          style={{height: '3px', backgroundColor: 'transparent'}}
          color="primary"
          variant="determinate"
          value={Math.ceil(((currentStep + 1) * 100) / layOutStore.onboardingSteps.length)}
        />
      </div>

      <div className="onboarding__body">
        {transitions((styles, step) => (
          <animated.div
            style={styles}
            className={cn(
              'animated-tab w-100 h-100 position-absolute d-flex flex-wrap align-items-center custom-scroll-y',
              getStepType(step) === OnboardingStepType.OPERATORS_INVITATION ? 'operators-invitation-step' : '',
            )}
          >
            {renderStep(step)}
          </animated.div>
        )
        )}
      </div>

      <div className="onboarding__footer">
        {backButtonVisible ? (
          <Button
            className="onboarding__next-btn"
            variant="contained"
            size="large"
            color="secondary"
            onClick={switchToPrevStep}
          >
            {t('Back')}
          </Button>
        ) : null}

        {renderNextButton()}
      </div>
      <ContextMenu />
    </Div100vh>
  );
});

export default OnboardingPage;
