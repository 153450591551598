import {useFormik} from 'formik';
import Long from 'long';
import {observer} from 'mobx-react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {animated, useTransition} from 'react-spring';
import useResizeObserver from 'use-resize-observer';
import * as Yup from 'yup';

import FormHelperText from 'o-ui/FormHelperText';
import FormLabel from 'o-ui/FormLabel';
import OutlinedTextInput from 'o-ui/Input/OutlinedTextInput';
import Select, {SelectChangeEvent} from 'o-ui/Select';

import {IMCWebWidgetConfig, MCWebWidgetConfig, entities} from '../../../../api/proto';
import ColorSelector from '../../../../components/ColorSelector';
import FileInputUploader from '../../../../components/FileUploader/FileInputUploader';
import {
  MAX_FORM_FIELD_LENGTH,
  WEB_WIDGET_DEFAULT_CHAT_BUTTON_SIZE,
  WEB_WIDGET_POSIBLE_CHAT_BUTTON_SIZES,
} from '../../../../constants';
import useChannelTypesList from '../../../../hooks/useChannelTypesList';
import Channel from '../../../../stores/Channel';
import {FileData} from '../../../../utils/file/fileReaders';
import jsonParse from '../../../../utils/json/jsonParse';
import jsonStringify from '../../../../utils/json/jsonStringify';
import {DEFAULT_POSITION} from '../../../../utils/widgetPositionUtils';
import ButtonSizeSelector from './ButtonSizeSelector';
import PositionSelector from './PositionSelector';
import WebWidgetFirstMessage from './WebWidgetFirstMessage';
import WebWidgetSnippetsList from './WebWidgetSnippetsList/WebWidgetSnippetsList';


export enum WebWidgetSettingStep {
  BASE_SETTING,
  FIRST_MESSAGE_SETTING,
  SNIPPETS_SETTING,
  DONE,
}

interface IProps {
  step?: WebWidgetSettingStep | null;
  newChannel?: Channel | null;
  onSubmit?: (name: string, webWidgetConfig: IMCWebWidgetConfig, fileLogo?: FileData | null) => void;
  colorOptions: string[];
  welcomeMessageOptions: string[];
  onChangeColor?: (color: string) => void;
  onChangeCompanyName?: (companyName: string) => void;
  onChangeFirstMessage?: (firstMessage: string) => void;
  onChangeLogo?: (file: FileData) => void;
  onChangeSnippets?: (snippets: MCWebWidgetConfig.IHelloSnippet[]) => void;
  type?: entities.OzekonChannelType | null;
  isOnboarding?: boolean;
  onChangeType?: (type: entities.OzekonChannelType) => void;
  onChangeChatButtonSize?: (value: string) => void;
  onChangeChatButtonPosition?: (props: React.CSSProperties) => void;
  onRest?: () => void;
}

export interface IWebWidgetSettingsFormRef {
  submitForm?: () => void;
  errors?: {[field: string]: any};
}

export const WebWidgetSettingsForm = observer(
  React.forwardRef<IWebWidgetSettingsFormRef, IProps>((props: IProps, ref) => {
    const {t} = useTranslation();
    const {ref: bodyRef, height = 0} = useResizeObserver<HTMLDivElement>();
    const [chosenFile, setChosenFile] = React.useState<FileData | null>(null);
    const [apiError, setApiError] = React.useState<string | null | undefined>('');
    const webWidgetConfig = props.newChannel?.webWidgetConfig;

    const sourcesList = useChannelTypesList();

    const positionProps = jsonParse<React.CSSProperties>(webWidgetConfig?.position) || DEFAULT_POSITION;

    const transitions = useTransition(props.step, {
      from: {
        opacity: 0,
      },
      enter: {opacity: 1},
      leave: {
        opacity: 0,
      },
      // config: {duration: 18000},
      onRest: props.onRest,
    });

    const formik = useFormik({
      initialValues: {
        name: props.newChannel?.name || '',
        displayCompanyName: webWidgetConfig?.displayCompanyName || '',
        domain: webWidgetConfig?.domain || '',
        displayAvatar: webWidgetConfig?.displayAvatar,
        displayColor: webWidgetConfig?.displayColor || props.colorOptions[0],
        displayWelcomeMessage: webWidgetConfig?.displayWelcomeMessage || props.welcomeMessageOptions[0],
        helloSnippets: webWidgetConfig?.helloSnippets || [],
        chatButtonSize: webWidgetConfig?.chatButtonSize
          ? `${webWidgetConfig?.chatButtonSize?.toString()}px`
          : WEB_WIDGET_DEFAULT_CHAT_BUTTON_SIZE,
        position: positionProps,
      },
      validateOnBlur: false,
      validateOnChange: false,
      validationSchema: Yup.object({
        name: Yup.string().required(t('Required')),
        displayCompanyName: Yup.string().required(t('Required')),
        domain: Yup.string(),
        displayAvatar: Yup.object(),
        displayColor: Yup.string(),
        displayWelcomeMessage: Yup.string(),
        helloSnippets: Yup.array(),
        chatButtonSize: Yup.string(),
        position: Yup.object().nullable(),
      }),
      onSubmit: (values) => {
        setApiError('');
        const {name, position, chatButtonSize, ...config} = values;

        const webWidgetConfig: IMCWebWidgetConfig = {
          ...config,
          chatButtonSize: Long.fromNumber(parseInt(values.chatButtonSize || '', 10)),
          position: jsonStringify(position),
        };

        props.onSubmit?.(name, webWidgetConfig, chosenFile);
      },
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
      errors: formik.errors,
    }));

    const handleCompanyNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(e);
      props.onChangeCompanyName?.(e.target.value);
    };

    const handleFileChange = (dataFiles: FileData[]) => {
      if (dataFiles.length) {
        setChosenFile(dataFiles[0]);
        props.onChangeLogo?.(dataFiles[0]);
      }
    };

    const handleColorChange = (value: string) => {
      formik.handleChange({
        target: {
          name: 'displayColor',
          value,
        },
      });
      props.onChangeColor?.(value);
    };

    const handleWelcomeMessageChange = (value: string) => {
      formik.handleChange({
        target: {
          name: 'displayWelcomeMessage',
          value,
        },
      });
      props.onChangeFirstMessage?.(value);
    };

    const handleChangeSnippets = (snippets: MCWebWidgetConfig.IHelloSnippet[]) => {
      formik.handleChange({
        target: {
          name: 'helloSnippets',
          value: snippets,
        },
      });
      props.onChangeSnippets?.(snippets);
    };

    const handleChangeType = (e: SelectChangeEvent) => {
      const type = parseFloat(e.target.value);
      props.onChangeType?.(type);
    };

    const handleChatButtonSizeChange = (value: string) => {
      formik.handleChange({
        target: {
          name: 'chatButtonSize',
          value: value,
        },
      });
      props.onChangeChatButtonSize?.(value);
    };

    const handlePositionChange = (pos: React.CSSProperties) => {
      formik.handleChange({
        target: {
          name: 'position',
          value: pos,
        },
      });
      props.onChangeChatButtonPosition?.(pos);
    };

    return (
      <form
        className="position-relative web-widget-creation-form_settings-container"
        onSubmit={formik.handleSubmit}
        style={height ? {minHeight: height} : {}}
      >
        {transitions((styles, step) =>
          !step ? (
            <animated.div
              style={styles}
              className="animated-tab w-100 h-100 position-absolute web-widget-creation-form_settings-block"
            >
              <div ref={bodyRef}>
                <div className="h4-medium mb-3">{t('settings_web_widget_manage_form_title')}</div>

                {props.isOnboarding && props.onChangeType ? (
                  <Select
                    className="w-100 mb-3"
                    selectClassName="w-100"
                    label={t('Channel')}
                    onChange={handleChangeType}
                    value={props.type?.toString()}
                    items={sourcesList}
                    required
                  />
                ) : null}

                <FormLabel className="mb-1">{t('settings_web_widget_manage_form_channel_name_label')}</FormLabel>
                <OutlinedTextInput
                  className="w-100"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  errorHelper={formik.errors.name || ' '}
                  maxLength={MAX_FORM_FIELD_LENGTH}
                  required
                  autoFocus
                />

                <FormLabel>{t('settings_web_widget_manage_form_company_name_label')}</FormLabel>
                <OutlinedTextInput
                  className="w-100"
                  name="displayCompanyName"
                  value={formik.values.displayCompanyName}
                  onChange={handleCompanyNameChange}
                  errorHelper={formik.errors.displayCompanyName || ' '}
                  textHelper={t('settings_web_widget_manage_form_company_name_helper')}
                  maxLength={MAX_FORM_FIELD_LENGTH}
                  required
                />

                <FormLabel className="mt-3">{t('settings_web_widget_manage_form_company_logo_label')}</FormLabel>
                <FileInputUploader className="w-100" onChange={handleFileChange} accept="image/*" errorHelper=" " />

                <FormLabel>{t('settings_web_widget_manage_form_website_domain_label')}</FormLabel>
                <OutlinedTextInput
                  className="w-100"
                  name="domain"
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  errorHelper={formik.errors.domain || ' '}
                  textHelper={t('settings_web_widget_manage_form_website_domain_helper')}
                  maxLength={MAX_FORM_FIELD_LENGTH}
                  required
                />

                <FormHelperText className="mt-2" error>
                  {apiError || ' '}
                </FormHelperText>
              </div>
            </animated.div>
          ) : step === WebWidgetSettingStep.FIRST_MESSAGE_SETTING ? (
            <animated.div
              style={styles}
              className="animated-tab w-100 h-100 position-absolute web-widget-creation-form_settings-block"
            >
              <div ref={bodyRef}>
                <div className="h4-medium">{t('settings_web_widget_manage_form_welcome_message_label')}</div>
                <WebWidgetFirstMessage
                  value={formik.values.displayWelcomeMessage}
                  options={props.welcomeMessageOptions}
                  onChange={handleWelcomeMessageChange}
                />

                <div className="h4-medium">{t('settings_web_widget_manage_form_appearance_label')}</div>

                <FormLabel className="mt-3">{t('settings_web_widget_manage_form_color_label')}</FormLabel>
                <ColorSelector
                  value={formik.values.displayColor}
                  options={props.colorOptions}
                  onChange={handleColorChange}
                />

                <FormLabel className="mt-3">{t('settings_web_widget_manage_form_button_size_label')}</FormLabel>
                <ButtonSizeSelector
                  className="w-100"
                  value={formik.values.chatButtonSize}
                  onChange={handleChatButtonSizeChange}
                  items={WEB_WIDGET_POSIBLE_CHAT_BUTTON_SIZES.map((size) => ({caption: size, value: size}))}
                />

                <PositionSelector
                  className="mt-3 pb-3"
                  position={formik.values.position}
                  onChange={handlePositionChange}
                />
              </div>
            </animated.div>
          ) : (
            <animated.div
              style={styles}
              className="animated-tab w-100 h-100 position-absolute web-widget-creation-form_settings-block"
            >
              <div ref={bodyRef}>
                <div className="h4-medium mb-3">{t('settings_web_widget_manage_form_channel_snippets_label')}</div>
                <WebWidgetSnippetsList snippets={formik.values.helloSnippets} onChange={handleChangeSnippets} />
              </div>
            </animated.div>
          ),
        )}
      </form>
    );
  }),
);

export default WebWidgetSettingsForm;
