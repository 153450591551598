import React from 'react';
import {useTranslation} from 'react-i18next';
import AuthInfoSlider from './AuthInfoSlider';

import logotype from '../../assets/images/logotype-white.svg';

export const AuthInfo = () => {
  const {t} = useTranslation();

  return (
    <div className="auth-info p-6 p-xl-11">
      <div className="auth-info__logo">
        <img className="auth-info__logo-img" src={logotype} alt="Logo" />
      </div>
      <div className="auth-info__logo-sub-title mt-6 mt-sm-5">{t('login_logo_subtitle')}</div>

      <AuthInfoSlider />
    </div>
  );
};

export default AuthInfo;
